import { HttpWrapper } from '../shared/services/rest.service';
import { StoreService } from '../shared/services/store.service';
import * as endpoints from './endpoints';

const storeKeys = {
    currentRegionId: 'currentRegionId',
    currentOrderId: 'currentOrderId',
    order: 'order',
    // orderLookups: 'orderLookups',
    // newOrderLookups: 'newOrderLookups',
    duplicateOrderSearchSetup: 'duplicateOrderSearchSetup'
};

let getStoredResult = function (itemKey, serviceUrl, orderId) {
    return new Promise((resolve, reject) => {
        try {
            const result = StoreService.getItem(itemKey);
            if (result == null || (_.hasIn(result, "orderId") && orderId && Number(result.orderId) !== Number(orderId))) {
                HttpWrapper.get(serviceUrl).then(freshResult => {
                    if (freshResult != null) {StoreService.setItem(itemKey, freshResult);}
                    resolve(freshResult);
                });
            } else {resolve(result);}
        } catch (err) {
            reject(err);
        }
    });
};

let getOrderRequestEndpoint = function(orderId, entityOnly=false) {
    let orderIdPart = _.gt(_.parseNumber(orderId, -2), -2) ? `/${orderId}` : "";
    let entityPart = entityOnly ? "/entity" : "";
    return `${endpoints.ORDERS}${orderIdPart}${entityPart}`;
}

export default {

    getOrderData (orderId) {
        return HttpWrapper.get(`${endpoints.ORDERS}/${orderId}/data`);
    },

    getOrderEntity (val) {
        return HttpWrapper.get(`${endpoints.ORDERS}/entity/${btoa(val)}`);
    },

    getEarnestDepositData (ordersId) {
        return HttpWrapper.get(`${endpoints.ORDERS}/${ordersId}/depositData`);
    },

    getOrderID (gfno) {
        return HttpWrapper.get(`${endpoints.ORDERS}/orderID/${btoa(gfno)}`, null);
    },

    getGfNo (orderId) {
        return HttpWrapper.get(`${endpoints.ORDERS}/${orderId}/gfNo`, null);
    },

    getPrimaryOrders () {
        return HttpWrapper.get(`${endpoints.ORDERS}/primaryOrders`, null);
    },

    getSecondaryOrders (ordersId) {
        return HttpWrapper.get(`${endpoints.ORDERS}/secondaryOrders/${ordersId}`, null);
    },

    getSecondaryOrderCount (ordersId, statusIDs) {
        return HttpWrapper.post(`${endpoints.ORDERS}/secondaryOrderCount/${ordersId}`, statusIDs, null);
    },

    getSecondaryFileMerge (orderId, mode) {
        return HttpWrapper.get(`${endpoints.ORDERS}/${orderId}/${mode}/secondaryFileMerge`, null);
    },

    secondaryFileMerge (data) {
        return HttpWrapper.post(`${endpoints.ORDERS}/secondaryFileMerge`, data, null);
    },

    getAutocompleteResults (data) {
        return HttpWrapper.post(`${endpoints.ORDERS}/autocomplete`, data, null);
    },

    getFacetSearchResults (request) {
        return HttpWrapper.post(`${endpoints.ORDERS}/facetsearch`, request, null);
    },

    getFacetDataSearchResults (request) {
        return HttpWrapper.post(`${endpoints.ORDERS}/facetdatasearch`, request, null);
    },

    orderIsValid (orderId) {
        return HttpWrapper.get(`${endpoints.ORDERS}/${orderId}/isvalid`);
    },

    setLastOrder (orderId) {
        return HttpWrapper.put(`${endpoints.ORDERS}/${orderId}`, null, null);
    },

    getOrderSummary (orderId) {
        return HttpWrapper.get(`${endpoints.ORDERS}/${orderId}/summary`, null);
    },

    getDynamicPrinterData (request) {
        return HttpWrapper.post(`${endpoints.ORDERS}/dynamicPrinterData`, request, null);
    },

    saveOrderSettings (orderSettings) {
        return HttpWrapper.put(`${endpoints.ORDERS}/settings`, orderSettings, null);
    },

    getOrderLookups (orderId) {
        return HttpWrapper.get(`${endpoints.ORDERS}/${orderId}/lookups`);
    },

    getOrderContacts (orderId) {
        return HttpWrapper.get(`${endpoints.ORDERS}/${orderId}/contacts`);
    },

    getOrderMain (orderId) {

        return new Promise((resolve, reject) => {
            HttpWrapper.get(`${endpoints.ORDERS}/${orderId}/main`)
                .then(result => {
                    if (result != null) {
                        StoreService.setItem(storeKeys.currentRegionId, result.regionID);
                        StoreService.setItem(storeKeys.currentOrderId, result.ordersID);
                        //StoreService.setItem(storeKeys.order, result.ordersID);
                        resolve(result);
                    } else {resolve(null);}
                })
                .catch(err => {reject(err);});
        });
    },

    addRole (data) {
        return HttpWrapper.post(`${endpoints.ORDERS}/AddRole/`, data);
    },

    updateRole (data) {
        return HttpWrapper.put(`${endpoints.ORDERS}/UpdateRole`, data);
    },

    deleteRole (orderId, roleId) {
        return HttpWrapper.delete(`${endpoints.ORDERS}/${orderId}/DeleteRole/${roleId}`);
    },

    deleteContacts (orderId, data) {
        return HttpWrapper.post(`${endpoints.ORDERS}/${orderId}/DeleteContacts`, data);
    },

    savePaperlessCloserSettings (data) {
        return HttpWrapper.put(`${endpoints.ORDERS}/SaveRolePCAccess/`, data);
    },

    saveOrderNote (note) {
        return HttpWrapper.put(`${endpoints.ORDERS}/${note.ordersID}/notes/`, note);
    },

    validateGFNo (gfno) {
        return HttpWrapper.get(`${endpoints.ORDERS}/validateGFNo/${btoa(gfno)}`, null);
    },

    saveNewOrder (newOrder) {
        return HttpWrapper.post(`${endpoints.ORDERS}`, newOrder, null);
    },

    copyExistingOrder (orderCopyInfo) {
        return HttpWrapper.post(`${endpoints.ORDERS}/copy`, orderCopyInfo, null);
    },

    getDuplicateSearchSetup () {
        return HttpWrapper.get(`${endpoints.ORDERS}/duplicatesearch/setup`, null);
    },

    getDuplicateSearchResultCount (request) {
        return HttpWrapper.post(`${endpoints.ORDERS}/duplicatesearch/total`, request);
    },

    getDuplicateSearchResult (request) {
        return HttpWrapper.post(`${endpoints.ORDERS}/duplicatesearch`, request);
    },

    getOrderSearchResult (request) {
        return HttpWrapper.post(`${endpoints.ORDERS}/ordersearch`, request, null);
    },

    getSimpleSearchResult (request) {
        return HttpWrapper.post(`${endpoints.ORDERS}/simplesearch`, request, null);
    },

    getNextFileNumber (model) {
        return HttpWrapper.post(`${endpoints.ORDERS}/nextfilenumber`, model);
    },

    setNextFileNumber (request) {
        return HttpWrapper.put(`${endpoints.ORDERS}/nextfilenumber`, request);
    },


    getNextTUFileNumber (titleUnitId) {
        return HttpWrapper.get(`${endpoints.ORDERS}/nextTUFileNo/${titleUnitId}`);
    },

    getNextEUFileNumber (escrowUnitId) {
        return HttpWrapper.get(`${endpoints.ORDERS}/nextEUFileNo/${escrowUnitId}`);
    },

    applyTemplate (data) {
        return HttpWrapper.put(`${endpoints.ORDERS}/applytemplate`, data);
    },

    getRecentOrders (searchRequest) {
        return HttpWrapper.post(`${endpoints.ORDERS}/recent`, searchRequest);
    },

    getCompanyContacts (companyId) {
        return HttpWrapper.get(`${endpoints.ORDERS}/companycontacts/${companyId}`);
    },

    getContact (contactId) {
        return HttpWrapper.get(`${endpoints.ORDERS}/contact/${contactId}`);
    },

    getLookups (orderId) {
        return HttpWrapper.get(`${endpoints.ORDERS}/${orderId}/lookups`);
    },

    saveOrderMain (orderInfo, changes) {
        return HttpWrapper.post(`${endpoints.ORDERS}/main`, { data: orderInfo, changes });
    },

    getBuyers (orderId) {
        return HttpWrapper.get(`${endpoints.ORDERS}/${orderId}/buyers`);
    },

    getBuyerSellerAddress (orderId, buyerSellerType) {
        return HttpWrapper.get(`${endpoints.ORDERS}/${orderId}/buyerSellerAddress/${buyerSellerType}`);
    },

    getSellers (orderId) {
        return HttpWrapper.get(`${endpoints.ORDERS}/${orderId}/sellers`);
    },

    saveBuyer (buyer, changes) {
        return HttpWrapper.put(`${endpoints.ORDERS}/UpdateBuyer`, { data: buyer, changes});
    },

    saveSeller (seller, changes) {
        return HttpWrapper.put(`${endpoints.ORDERS}/UpdateSeller`, { data: seller, changes});
    },

    updateSellerProceeds (data) {
        return HttpWrapper.put(`${endpoints.ORDERS}/UpdateSellerProceeds`, data);
    },

    reorderBuyerSellers (data) {
        return HttpWrapper.put(`${endpoints.ORDERS}/buyersellers/reorder`, data);
    },

    deleteBuyer (orderId, buyerId) {
        return HttpWrapper.delete(`${endpoints.ORDERS}/${orderId}/DeleteBuyer/${buyerId}`);
    },

    deleteSeller (orderId, sellerId) {
        return HttpWrapper.delete(`${endpoints.ORDERS}/${orderId}/DeleteSeller/${sellerId}`);
    },

    getSignatureBlock (buyerSellerId) {
        return HttpWrapper.get(`${endpoints.ORDERS}/signature/${buyerSellerId}`);
    },

    updateSignatureBlock (signature) {
        return HttpWrapper.post(`${endpoints.ORDERS}/signature/`, signature);
    },

    getSignatureImageData (buyerSellerId) {
        return HttpWrapper.get(`${endpoints.ORDERS}/signature/${buyerSellerId}/image/data`);
    },

    updateSignatureImage (buyerSellerId, imageFile) {
        return HttpWrapper.post(`${endpoints.ORDERS}/signature/${buyerSellerId}/imageupload`, imageFile);
    },

    removeSignatureImage (buyerSellerId) {
        return HttpWrapper.delete(`${endpoints.ORDERS}/signature/${buyerSellerId}/removeimage`);
    },

    /**
     * Deletes the specified order note.
     * @param {int} noteId - ID of the note to delete.
     */
    getOrderNotes (orderId, searchRequest) {
        return HttpWrapper.post(`${endpoints.ORDERS}/${orderId}/notes/`, searchRequest);
    },

    deleteOrderNotes (noteIDs) {
        return HttpWrapper.post(`${endpoints.ORDERS}/deleteNotes/`, noteIDs);
    },

    resolveOrderNotes (noteIDs) {
        return HttpWrapper.post(`${endpoints.ORDERS}/resolveNotes/`, noteIDs);
    },

    /**
     * Retrieves the full note text for the specified note.
     * @param {int} orderId - ID of the order containing the note.
     * @param {int} noteId - ID of the note whose text should be retrieved.
     */
    getOrderNoteText (orderId, noteId) {
        return HttpWrapper.get(`${endpoints.ORDERS}/${orderId}/notes/${noteId}/text`);
    },

    /**
     * Retrieves a list of user email addresses.
     */
    getUserEmailList (orderId) {
        return HttpWrapper.get(`${endpoints.ORDERS}/UserEmailList/${orderId}`);
    },

    getRole (roleId) {
        return HttpWrapper.get(`${endpoints.ORDERS}/role/${roleId}`);
    },

    saveRolePCAccess (data) {
        return HttpWrapper.put(`${endpoints.ORDERS}/SaveRolePCAccess/`, data);
    },

    saveCDFRolesPCAccess (data) {
        return HttpWrapper.put(`${endpoints.ORDERS}/SaveCDFRolePCAccess/`, data);
    },

    assignCompany (data) {
        return HttpWrapper.post(`${endpoints.ORDERS}/AssignCompany/`, data);
    },

    assignContact (data) {
        return HttpWrapper.post(`${endpoints.ORDERS}/AssignContact/`, data);
    },

    getContacts (companyId) {
        return HttpWrapper.get(`/companies/${companyId}/contacts`);
    },

    addCompany (company) {
        return HttpWrapper.post('/companies/Add', company);
    },

    updateCompany (company) {
        return HttpWrapper.put('/companies/Update', company);
    },

    addContact (contact) {
        return HttpWrapper.post('/companies/AddContact', contact);
    },

    updateContact (contact) {
        return HttpWrapper.put('/companies/UpdateContact', contact);
    },

    saveContact (contactSaveRequest) {
        if (contactSaveRequest && contactSaveRequest.data) {
            const isNew = (!(contactSaveRequest.data.contactID > 0));
            if (isNew) {
                return this.addContact(contactSaveRequest.data);
            }
            return this.updateContact(contactSaveRequest);

        }
    },

    getPaperlessCloserAccess (paperlessCloserAccessId) {
        return HttpWrapper.get(`/companies/contacts/pcaccess/${paperlessCloserAccessId}`);
    },

    getCdfPaperlessCloserAccess (cdfPaperlessCloserAccessId) {
        return HttpWrapper.get(`/companies/contacts/cdfpcaccess/${cdfPaperlessCloserAccessId}`);
    },

    updatePcAccess (saveRequest) {
        return HttpWrapper.put('/companies/UpdatePcAccess', saveRequest);
    },

    updateCdfPcAccess (saveRequest) {
        return HttpWrapper.put('/companies/UpdateCdfPcAccess', saveRequest);
    },

    emailPaperlessCloserCredentials (paperlessCloserAccessId) {
        return HttpWrapper.get(`/companies/contacts/pcaccess/emailcredentials/${paperlessCloserAccessId}`);
    },

    checkPCAccessUserNameExists (pcUserName) {
        return HttpWrapper.get(`/companies/contacts/pcaccess/usernameexists/${pcUserName}`);
    },

    getSellerAsLender (orderId, loanId) {
        return HttpWrapper.post(`/orders/${orderId}/loan/${loanId}/setSellerAsLender`);
    },

    setAltaFlag (orderId, isAlta) {
        var isAltaValue = isAlta ? 'true' : 'false';
        return HttpWrapper.post(`/orders/${orderId}/SetAltaFlag/${isAltaValue}`);
    },

    saveEscrowMemo (ordersID, memo) {
        return HttpWrapper.put(`/orders/${ordersID}/escrowMemo/`, { data: memo, changes: null }, null);
    },

    saveAutoRecreateSource (ordersID, id) {
        return HttpWrapper.put(`/orders/${ordersID}/autoRecreateSource/${id}`, null, null);
    },

    saveEscrowUnit (ordersID, id) {
        return HttpWrapper.put(`/orders/${ordersID}/escrowUnit/${id}`, null, null);
    },

    saveEscrowAccount (ordersID, id) {
        return HttpWrapper.put(`/orders/${ordersID}/escrowAccount/${id}`, null, null);
    },

    //loans
    getLoans (orderId) {
        return HttpWrapper.get(`${endpoints.ORDERS}/${orderId}/loans`, null);
    },

    reorderLoans (data) {
        return HttpWrapper.post(`${endpoints.ORDERS}/loans/reorder`, data);
    },

    saveLoan (loan, changes) {
        return HttpWrapper.post(`/orders/loan`, { data: loan, changes });
    },

    deleteLoan (orderId, loans) {
        return HttpWrapper.post(`/orders/${orderId}/DeleteLoan`, loans);
    }
};